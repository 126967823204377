<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
        <Toolbar class="p-mb-4">
<!--          <template v-slot:left>-->
          <template #start>
            <div style="font-size: 1.4em;" class="p-mr-3" >{{ $t('Orders') }}</div>
            <AddNewButton :disabled="!allDataLoaded" :label="$t('Add')" @click="addNewItem(false)" />
          </template>
<!--          <template v-slot:right>-->
          <template #end>
<!--            <Button  disabled @click="sendAppointmentNotifications" label="Send appointment notifications" class="p-button-secondary p-button-raised p-mr-2" icon="pi pi-refresh"></Button>-->
            <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>
<!--        <div>{{ httpClient.endPoint }}</div>-->

        <Spinner v-if="isLoading"></Spinner>
<!--        <div v-else-if="!isLoading && !items || !items.length" class="p-text-center p-m-2 p-p-6">{{ $t('No data available') }}</div>-->
        <div v-else-if="!isLoading && !items || !items.length" class="no-data-block">{{ $t('No data available') }}</div>

<!--        {name: 'car_logo', title: '',  width: '54px', sortable: false}, -- after name: 'time', title: 'Time',-->
<!--        <template v-else-if="!isLoading && !$store.state.tabletLayout">-->
<!--        <template v-else-if="!isLoading">-->
        <template v-else-if="!isLoading">
          <DesktopDataTable v-for="(date, index) of dates"
                            v-show="datedItems[index].length"
                            :key="index"
                            class="datatable"
                            :dataTableIsLoading="dataTableIsLoading"
                            :tableName="parseDate(date)"
                            :itemsArrayIsEmpty="itemsArrayIsEmpty"
                            :sort="sort"
                            :striped="false"
                            :hightlightTable="isTableHightlight(date)"
                            :showSearchInput="false"
                            :mastersTime="computeMastersTime(date)"
                            :actionButtonsCount="actionButtonsCount"
                            :headers="[
                {name: 'start', title: 'Start', sortable: false},
                {name: 'number', title: 'Number', sortable: false},
                // {name: 'customer', title: 'Customer', width: '18%', sortable: false},
                // {name: 'order', title: 'Order data', width: '20%', sortable: false},
                // {name: 'time', title: 'Time', width: '70px', sortable: false},
                // {name: 'car', title: 'Car data', width: '14%', sortable: false},
                // {name: 'area_id', title: 'Area', width: '6%', sortable: false},
                // {name: 'masters', title: 'Masters', width: '10%', sortable: false},
                // {name: 'amount', title: 'Amount', width: '90px', sortable: false},
                // {name: 'state', title: 'State', width: '16%', sortable: false},
                // {name: '', title: '', width: '45px', sortable: false},
                // {name: 'start', title: 'Time', sortable: false},
                // {name: 'responsible', title: 'Res.', sortable: false},
                // {name: 'number', title: 'Number', sortable: false},
                {name: 'customer', title: 'Customer', width: '17%', sortable: false},
                {name: 'order', title: 'Order data', width: '20%', sortable: false},
                {name: 'time', title: 'Time', width: '6%', sortable: false},
                {name: 'car', title: 'Car data', width: '16%', sortable: false},
                {name: 'area_id', title: 'Area', width: '6%', sortable: false},
                {name: 'responsible', title: 'Res.', width: '4%', sortable: false},
                {name: 'masters', title: 'Masters', width: '9%', sortable: false},
                {name: 'amount', title: 'Sum', width: '8%', sortable: false},
                {name: 'state', title: 'State', width: '9%', sortable: false},
                {name: '', title: '', width: '2%', sortable: false},
                {name: '', title: '', width: '2%', sortable: false},
            ]">
            <template v-slot:body>
              <template v-for="(item, index) of datedItems[index]" :key="index">
                <tr v-show="item.showTime">
                  <td colspan ="20">
                    <div class="p-d-flex p-ai-center">
                      <div style="width: 60px" class="">{{ formatTime(item.start - 900) }}</div>
                      <div>
                        <AddNewButton :label="$t('Add')" @click="addNewItem(item.start - 900)" />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr :class="{'updated-item': isItemUpdated(item.id)}">
                  <td @click="toggleShowTime(item)" :class="{'warning-color':  (+new Date() > +new Date(item.start * 1000)) && item.state === 1 }" style="cursor: pointer">{{ formatTime(item.start) }}</td>
                  <td>
                    <div>{{ item.number }}</div>
                    <div v-if="item.parentOffer?.number" class="p-pt-1" style="font-size: 0.85em">
                      <router-link :to="{ path: '/offers', query: { search: item.parentOffer.number } }" target="_blank">
                        <i style="font-size: 0.90em" class="ti-clipboard p-mr-1"></i>
                        <span>{{ item.parentOffer.number }}</span>
                      </router-link>
                    </div>
                    <div v-else-if="item.parentOrder?.number" class="p-pt-1" style="font-size: 0.85em">
                      <router-link :to="{ path: `/orders/${item.parentOrder.number}` }" target="_blank">
                        <i style="font-size: 0.90em" class="ti-hummer p-mr-1"></i>
                        <span>{{ item.parentOrder.number }}</span>
                      </router-link>
                    </div>
                  </td>
                  <td>
                    <TableCustomerData :customer="item.customer" @change-customer-status="changeCustomerStatus"/>
                  </td>
                  <td>
                    <div>
                      <a href="#" @click.prevent="toggleShowPositionTable(item)">
                        <i style="font-size: 0.9em" class="p-mr-1 pi" :class="item.showPositionsTable ? 'pi-minus': 'pi-plus'"></i>
                        <span class="text">{{ item.name || item.number }}</span>
                      </a>
                      <span @click="toggleWarning(item)" v-show="item.staff_comment" class="p-link icon p-ml-2">
                        <i class="ti-comment-alt" :class="{'warning-color':item.is_warning}"></i>
                      </span>
<!--                      <div v-if="item.parentOffer?.number" style="font-size: 0.85em;">-->
<!--                        <span class="p-mr-1">From:</span>-->
<!--                        <router-link :to="{ path: '/offers', query: { search: item.parentOffer.number } }" target="_blank">-->
<!--                          <i style="font-size: 0.90em" class="ti-clipboard p-mr-1"></i>-->
<!--                          <span>{{ item.parentOffer.number }}</span>-->
<!--                        </router-link>-->
<!--                      </div>-->
<!--                      <div v-else-if="item.parentOrder?.number" style="font-size: 0.85em;">-->
<!--                        <span class="p-mr-1">From:</span>-->
<!--                        <router-link :to="{ path: `/orders/${item.parentOrder.number}` }" target="_blank">-->
<!--                          <i style="font-size: 0.90em" class="ti-hummer"></i>-->
<!--                          <span>{{ item.parentOrder.number }}</span>-->
<!--                        </router-link>-->
<!--                      </div>-->
                    </div>
                    <div class="table-small-text">
                      <span :class="{'order-created-by-customer':item.is_created_by_customer}">
<!--                        <span>{{ $t('Created') }}: </span>-->
                        <span v-if="item.created_at">{{ formatDate(item.created_at) }}, </span>
                        <span v-if="item.creator">
<!--                          <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}<span> ({{ item.creator.first_name }} {{ item.creator.last_name }})</span></span>-->
                          <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}</span>
                          <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>
                        </span>
                      </span>
                    </div>
                  </td>
                  <td>
                    <span v-if="item.time && +item.time !== 0">{{ item.time }} {{ $t('h') }}.</span>
                    <i v-else class="ti-minus"></i>
                  </td>
<!--                  <td>-->
<!--                    <div v-if="item.car?.make?.logo_path" class="p-mr-2">-->
<!--                      <img style="max-width: 40px; max-height: 40px; display: block" :src="settings.apiEndPoint + settings.pathToCarLogosDir + item.car.make.logo_path">-->
<!--                    </div>-->
<!--                  </td>-->
                  <td>
                    <span v-if="item.car" >
                      <router-link :to="{ path: `/cars/${item.car.id}` }" target="_blank">
                        <i class="ti-car p-mr-1"></i>
                        <span>{{ item.car.make?.name }} {{ item.car.model?.name }}</span>
<!--                        <span class="p-mr-1" v-if="item.car.make">{{ item.car.make.name }}</span>-->
<!--                        <span class="p-mr-1" v-if="item.car.model">{{ item.car.model.name }}</span>-->
                      </router-link>
                      <div class="table-small-text p-d-flex p-ai-center">
                        <div>
                          <span>{{ item.car.plate_number }}</span>
                          <span v-if="item.car.year">, {{ item.car.year }}</span>
                        </div>
                        <div>
                          <a target="_blank" :href="'https://eteenindus.mnt.ee/public/soidukTaustakontroll.jsf?regMark=' + item.car.plate_number" class="p-p-2">
<!--                          <i class="ti-info-alt"></i>-->
                            <i style="font-size: 0.85rem" class="pi pi-external-link"></i>
                          </a>
                        </div>
                      </div>
                    </span>
                    <i v-else class="ti-minus"></i>
                  </td>
                  <td>
                    <span v-if="item.area?.name">{{ item.area.name }}</span>
                    <i v-else class="ti-minus"></i>
                  </td>
                  <td class="avatar-td">
                    <AvatarsCell :responsible="item.responsible"/>
                  </td>
                  <td class="avatar-td">
                    <AvatarsCell :masters="item.masters"/>
                  </td>
                  <td>
<!--                    <span>{{ calculateGrandTotal(item) }}</span>-->
                    <span>{{ formatCurrency(item.grand_total) }}</span>
                    <template v-if="item.is_invoiced && item.unpaidSum && +item.unpaidSum">
                      <div style="font-size: 0.8rem" class="warning-color">
                        Unpaid: {{ formatCurrency(item.unpaidSum) }}
                      </div>
                    </template>
                    <template v-else-if="!item.is_invoiced && (item.state === 7 || item.state === 8) && +item.grand_total">
                      <div style="font-size: 0.8rem" class="warning-color">
                        Unpaid: {{ formatCurrency(item.grand_total) }}
                      </div>
                    </template>
                  </td>
                  <td>
                    <OrderStateButton :ref="'orderStateBtn-' + item.id"
                                      @change-order-state-to-waiting-on-click="changeOrderStateToWaitingOnClick"
                                      :item="item"
                                      @update-item="updateOrder"
                                      :canChangeState="true"/>
                  </td>
                  <td>
<!--                    <a href="#" @click.prevent="item.called = !item.called" class="p-link table-link-icon &#45;&#45;text-color-secondary" :class="{'warning-color' : !item.called}">-->
                    <div v-if="item.state > 5 && item.state !== 12"
                         @click.prevent="toggleCalled(item)"
                         class="p-link table-link-icon green-color"
                         :class="{'warning-color' : !item.called_at || !item.called_by}">
                      <i class="pi pi-phone"></i>
                    </div>
<!--                    <i style="font-size: 0.90rem" class="pi pi-phone"></i>-->
                  </td>
                  <td>
                    <OrderDocumentsButton :orderData="item"/>
                  </td>
                  <td>
<!--                    <div class="table__actions-column-data">-->
                    <div class="p-d-flex">
<!--                      <ViewButton  @click="openOrderInNewWindow(item.number)"></ViewButton>-->
<!--                      <router-link @click.prevent="viewOrder($event, item.state)" :to="{ path: `/customers/${item.id}` }" target="_blank"><ViewButton :disabled="item.state === 1 || item.state === 3 || item.state === 5"></ViewButton></router-link>-->

                      <router-link :to="{ path: `/orders/${item.number}` }"><ViewButton/></router-link>
                      <EditButton :disabled="(disableEditButton && isItemUpdated(item.id)) || !allDataLoaded || dataTableIsLoading" @click="editItem(item)" />
                      <DeleteButton :disabled="!allDataLoaded || dataTableIsLoading" @click="confirmItemDelete({id: item.id, name: item.name})"
                                    v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>
                    </div>
                  </td>
                </tr>
                <tr v-show="item.showPositionsTable" class="table-opened-info">
                  <td colspan="20" style="padding:0!important;">
                    <PositionsTable :item="item" :isSpoilerTable="true" @update-items="updateItems"/>
                  </td>
                </tr>
                <tr v-show="item.showTime">
                  <td colspan="20">
                    <div class="p-d-flex p-ai-center">
                      <div style="width: 60px" class="">{{ formatTime(item.start + 900) }}</div>
                    <div>
                      <AddNewButton :label="$t('Add')" @click="addNewItem(item.start + 900)" />
                    </div>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </DesktopDataTable>
        </template>

        <OwnPaginator v-show="items?.length"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

        <OrderModal :visible="itemModal"
                    :item="item"
                    :masters="masters"
                    :responsibles="responsibles"
                    :taxes="taxes"
                    :services="services"
                    :suppliers="suppliers"
                    :areas="areas"
                    :warehouseItems="warehouseItems"
                    @update-request-state-history="updateRequestStateHistory"
                    @change-updated-item-id="updateOrder"
                    @change-is-warning="changeIsWarning"
                    @update-items="updateItems"
                    @update-item="updateOrder"
                    @update-customer="updateCustomer"
                    @close="closeItemModal">
        </OrderModal>

        <ConfirmDeleteModal
            :visible="confirmItemDeleteModal"
            @close="closeConfirmItemDeleteModal"
            @delete-item="deleteItem"
            :data="itemToDeleteData">
        </ConfirmDeleteModal>

        <ChangeOrderToWaitingModal :visible="changeOrderToWaitingModal"
                                   :orderData="orderToWaitingData"
                                   :showSpinner="dataIsSending"
                                   @change-state="changeOrderStateToWaiting"
                                   @close="closeChangeOrderToWaitingModal"/>
			</div>
		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import OrderModal from '@/pages/orders/components/OrderModal'
// import Spinner from "@/components/Spinner";
import RequestPositionStates from '@/translations/states/RequestPositionStates'
import OrderStateButton from '@/pages/orders/components/OrderStateButton'
import formatMixins from '@/mixins/formatMixins'
import PositionsTable from '@/components/PositionsTable'
import httpMixins from '@/mixins/httpMixins'
import datatableMixins from '@/mixins/datatableMixins'
import TableCustomerData from "@/components/TableCustomerData";
import OrderDocumentsButton from "@/pages/orders/components/OrderDocumentsButton";
import settings from "@/settings";
import SalesInvoiceStates from "@/translations/states/SalesInvoiceStates";
import AvatarsCell from "@/components/AvatarsCell";
import ChangeOrderToWaitingModal from "@/pages/orders/components/ChangeOrderToWaitingModal";

export default {
  mixins: [ formatMixins, httpMixins, datatableMixins ],
  components: { OrderModal,OrderStateButton,PositionsTable,TableCustomerData,OrderDocumentsButton,AvatarsCell,ChangeOrderToWaitingModal },
	data() {
		return {
      dataIsSending: false,
      openedItemsIds: [],
      // disableEditButton: false,
      // warehouseItems: null,
      // masters: null,
      // responsibles: null,
      // taxes: null,
      // services: null,
      // suppliers: null,
      // areas: null,
      // isLoading: true,
      dates: [],
      datedItems: [],
      showTime: {},
      fieldsExpand: 'responsible,customer,customer.address,customer.customerStatus,creator,masters,positions,positions.history,positions.request_state_history,consumable,car,area,recommendation_history,state_history,comments_history,parentOffer,parentOrder,unpaidSum,sales_invoice_issued_date',
      apiLink: 'order/get-orders',
      routePath: '/orders',
      // searchData: '',
      sort: {
        column: 'start',
        direction: '',
      },
			// items: [],
      // itemModal: false,
      // itemDeleteModal: false,
      // confirmItemDeleteModal: false,
      // itemToDelete: {},
			// item: {},
      // updatedItemId: null,
			// submitted: false,
      // pagination: {
      //   totalCount: null,
      //   pageCount: null,
      //   currentPage: 1,
      //   perPage: 25
      // },
      // RequestPositionStates: RequestPositionStates[this.$i18n.locale],
      RequestPositionStates,
      SalesInvoiceStates
      // settings,
      // constants,
      // settings,
		}
	},
  watch: {
    // '$store.state.updatedOrderId'(id) {
    //   if (!id) return false
    //
    //   if (id === this.itemData?.id) {
    //     this.itemData.orderWasUpdatedByThirdParty = true
    //     this.editingIsDisabled = true
    //     this.customerDisabled = true
    //     this.carDisabled = true
    //
    //     // this.$toast.add({severity:'error', summary: 'Заказ был обновлен.', detail: 'Что-бы продолжить работу закройте его и откройте снова.' });
    //   } else {
    //     console.log('aa')
    //     // this.$emit('change-updated-item-id', id)
    //   }
    //
    //   setTimeout(() => {
    //     this.$store.commit('changeUpdatedOrderId', null)
    //   }, 100)
    // },
    '$store.state.updateOrdersPage'(boolean) {
      if (boolean) {
        this.getItems()
      }
    },
    // '$store.state.updatedOrderId'(id) {
    //   if (id) {
    //     const foundItem = this.items?.find(item => item.id === id)
    //     if (foundItem) {
    //       this.getItems()
    //     }
    //   }
    //   setTimeout(() => {
    //     this.$store.commit('changeUpdatedOrderId', null)
    //   }, 100)
    //   // this.$store.commit('changeUpdatedOrderId', null)
    // },
    // '$route'() {
    //   this.checkRouteQuery()
    //   if (this.$route.path === '/login' || this.$route.path !== '/orders') {
    //     return false
    //   }
    //
    //   if(!this.isLoading) {
    //     if (this.$route.query.search) {
    //       this.getItems(false)
    //     } else {
    //       this.getItems()
    //     }
    //   }
    // },
    // '$store.state.firstLayerIsOpened'() {
    //   if (!this.$store.state.firstLayerIsOpened) {
    //     if (this.itemModal) {
    //       this.closeItemModal()
    //     }
    //     if (this.confirmItemDeleteModal) {
    //       this.closeConfirmItemDeleteModal()
    //     }
    //   }
    // },
    // 'pagination.perPage'() {
    //   if (this.$route.path === '/orders') {
    //     localStorage.setItem('ordersPerPage',this.pagination.perPage)
    //   }
    // }
    // '$store.state.user'() {
    //   this.getPageData()
    // }
  },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.checkRouteQuery()
    this.getPageData()

    // this.getTaxes()
    // this.getMasters()
    // this.getResponsibles()
    // this.getServices()
    // this.getSuppliers()
    // this.getAreas()
    // this.getWarehouseItems()
  },
  methods: {
    getPageData() {
      // if (!this.$store.state.user?.role) return false
      // console.log('getPageData')
      this.getItems()
      this.getModalData()
    },
    updateCustomer(user, orderId) {
      const ordersWithSameCustomer = this.items?.filter(i => i.customer?.id === user.id && i.id !== orderId)

      if (ordersWithSameCustomer?.length) {
        ordersWithSameCustomer.forEach(o => o.customer = user)
        this.constructOrdersTable()
      }
    },
    async sendAppointmentNotifications() {
      try {
        const { status, data } = await httpClient.post('notification/send-appointment-notifications')
        console.log(data)
        if (status === 200) {
          // item.warning = !item.warning
        }
      } catch(err) {
        this.showError(err)
      }
    },
    updateRequestStateHistory(orderId, positionId, requestStateHistory, newState) {
      if (!orderId || !positionId || !requestStateHistory) return false

      const order = this.items.find(o => o.id === orderId)
      if (!order) return false

      const position = order.positions?.find(p => p.id === positionId)
      if (position) {
        position.request_state_history = requestStateHistory
        position.state = newState
      }
    },
    addNewItem(time = false) {
      this.item = {}
      if (time) {
        this.item.start = time
      }

      this.submitted = false
      this.itemModal = true
      if (!this.$store.state.firstLayerIsOpened) {
        this.$store.commit('toggleFirstLayer', true)
      }
    },
    async updateOrder(id, sendSocket = false) {
      if (!id) return false
      this.updatedItemId = id

      let foundItem = this.items?.find(item => item.id === id)
      if (foundItem) {
        const indexOfFoundItem = this.items.indexOf(foundItem)
        if (indexOfFoundItem !== -1) {
          const itemData = await this.getOrderById(id)
          if (itemData) {
            this.items[indexOfFoundItem] = itemData
            this.changeItemsWithSameUserOrCar(itemData)
            this.constructOrdersTable()
          }
        }
      }

      if (sendSocket && this.$store.state.webSocket?.readyState === 1) {
        this.$store.state.webSocket.send(JSON.stringify({'action' : 'updateOrder', 'order_id' : id}))
      }

      // changeItemsWithSameUserOrCar() {
      //   const customers = this.items?.filter(i => i.customer?.id === )
      // },
    },
    changeItemsWithSameUserOrCar(itemData) {
      const ordersWithSameCustomer = this.items?.filter(i => i.customer?.id === itemData.customer?.id && i.id !== itemData.id)
      if (ordersWithSameCustomer?.length) {
        ordersWithSameCustomer.forEach(o => o.customer = itemData.customer)
      }
      const ordersWithSameCar = this.items?.filter(i => i.car?.id === itemData.car?.id && i.id !== itemData.id)
      if (ordersWithSameCar?.length) {
        ordersWithSameCar.forEach(o => o.car = itemData.car)
      }
    },
    async getOrderById(id) {
      this.dataIsSending = true
      try {
        const { data, status } = await httpClient.post(`order/get-by-id?expand=${this.fieldsExpand}`, { id })
        if (status === 200 && data?.success && data.item) {
          return data.item
        } else {
          return null
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.dataIsSending = false
        this.$store.commit('changeUpdatedOrderId', null)
      }
    },
    isTableHightlight(date) {
      if (!date) return false

      const now = new Date()
      date = new Date(date)
      return date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear()
    },
    // openOrderInNewWindow(orderNumber) {
    //   // const routeUrl = this.$router.resolve({
    //   //   path: `/orders/${orderNumber}`
    //   // })
    //   // window.open(routeUrl .href, '_blank');
    //
    //   this.$router.push({
    //     path: `/orders/${orderNumber}`,
    //   })
    // },
    async toggleCalled(item) {
      if (!item) return false
      try {
        const { status, data } = await httpClient.post('order/toggle-called', {id: item.id})
        if (status === 200 && data?.success) {
          if (data.called_at) {
            item.called_at = data.called_at
            item.calledBy = {
              first_name: this.$store.state.user.first_name,
              last_name: this.$store.state.user.last_name,
            }
            item.called_by = this.$store.state.user.id
          } else {
            item.called_at = null
            item.calledBy = null
            item.called_by = null
          }
        } else if (data?.error) {
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
        }
      } catch(err) {
        // item.is_warning = !item.is_warning
        this.showError(err)
      }
    },
    async toggleWarning(item) {
      // const item = this.items.find(item => item.id === itemId)
      if (!item) return false
      item.is_warning = !item.is_warning
      try {
        const { status } = await httpClient.post('order/toggle-warning', { id: item.id, state: item.is_warning })
        if (status !== 200) {
          item.is_warning = !item.is_warning
        }
      } catch(err) {
        item.is_warning = !item.is_warning
        this.showError(err)
      }
    },
    changeIsWarning(id, isWarning) {
      const item = this.items.find(item => item.id === id)
      if (!item) return false
      item.is_warning = isWarning
    },
    toggleShowTime(order) {
      order.showTime = !order.showTime
    },
    async getItems(showSpinner = false) {
      this.dataTableIsLoading = true
      this.disableEditButton = true
      if (showSpinner) {
        this.isLoading = true
      }
      // console.log(this.searchData, typeof this.searchData)
      try {
        // const { status, data, headers } = await httpClient(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`)
        const { status, data, headers } = await httpClient({
        // const { data } = await httpClient({
          url: this.apiLink,
          // method: 'post',
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
          },
          // data: {}
        })

        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']

          this.items = data

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }

          this.constructOrdersTable()
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.disableEditButton = false
        this.$store.commit('updateOrdersPage', false)
      }
    },
    constructOrdersTable() {
      if (!this.items?.length) return
      let dates = []
      this.items.forEach(item => {
        const date = new Date(item.start * 1000)
        const day = String(date.getDate()).length === 1 ? '0' + String(date.getDate()) : date.getDate()
        const month = String(date.getMonth() + 1).length === 1 ? '0' + String(date.getMonth() + 1) : date.getMonth() + 1
        const year = date.getFullYear()
        const fullDate = `${year}-${month}-${day}`
        if (dates.indexOf(fullDate) === -1) {
          dates.push(fullDate)
        }
      })

      if (this.openedItemsIds.length) {
        this.items.forEach(item => {
          if (this.openedItemsIds.indexOf(item.id) !== -1) {
            item.showPositionsTable = true
          }
        })
      }

      this.dates = dates.sort((a, b) => +new Date(a) - +new Date(b))
      // this.dates = dates.sort((a, b) => new Date(a.start) - new Date(b.start))

      let i = 0
      for (let date of this.dates) {
        this.datedItems[i] = this.items.filter(item => {
          const itemDate = new Date(item.start * 1000)
          return +itemDate.getFullYear() === Number(date.substr(0, 4)) &&
              +itemDate.getMonth() + 1 === Number(date.substr(5, 2)) &&
              +itemDate.getDate() === Number(date.substr(8, 2))
        }).sort((a, b) => a.start - b.start)
        i++
      }
    },
    parseDate(date) {
      const parsedDate = new Date(date)
      const parsedDateYear = parsedDate.getFullYear()
      const parsedDateMonth = parsedDate.getMonth()
      const parsedDateDay = parsedDate.getDate()
      const parsedDay = new Date(parsedDateYear, parsedDateMonth, parsedDateDay)

      const todayDate = new Date()
      const todayYear = todayDate.getFullYear()
      const todayMonth = todayDate.getMonth()
      const todayDay = todayDate.getDate()
      const today = new Date(todayYear, todayMonth, todayDay)

      const yesterday = new Date(todayYear, todayMonth, todayDay - 1)
      const tomorrow = new Date(todayYear, todayMonth, todayDay + 1)

      let day = ''
      // if (parsedDate.getDate() === today.getDate() &&
      //     parsedDate.getMonth() === today.getMonth() &&
      //     parsedDate.getFullYear() === today.getFullYear()) {
      //       day = ' - ' + this.$t('Today')
      // } else if (parsedDate.getDate() + 1 === today.getDate() &&
      //     parsedDate.getMonth() === today.getMonth() &&
      //     parsedDate.getFullYear() === today.getFullYear()) {
      //       day = ' - ' + this.$t('Yesterday')
      // } else if (parsedDate.getDate() - 1 === today.getDate() &&
      //     parsedDate.getMonth() === today.getMonth() &&
      //     parsedDate.getFullYear() === today.getFullYear()) {
      //       day = ' - ' +  this.$t('Tomorrow')
      // }

      if (+parsedDay === +today) {
        day = ' - ' + this.$t('Today')
      } else if (+parsedDay === +yesterday) {
        day = ' - ' + this.$t('Yesterday')
      } else if (+parsedDay === +tomorrow) {
        day = ' - ' +  this.$t('Tomorrow')
      }

      return this.formatDay(parsedDate / 1000) + ' ' + day
      // return parsedDate.toLocaleString(this.$store.state.branchData.locale, { timeZone: this.$store.state.branchData.timezone, year: 'numeric', month: 'numeric', day: 'numeric' }) + ' ' + day
    },
    async deleteItem() {
      console.log('delete order')
    },
    computeMastersTime(date) {
      const orders = [...this.items].filter(item => {
            const itemDate = new Date(item.start * 1000)
            const day = itemDate.getDate()
            const month = itemDate.getMonth()
            const year = itemDate.getFullYear()

            const tableDate = new Date(date)
            return day === tableDate.getDate() &&
                month === tableDate.getMonth() &&
                year === tableDate.getFullYear() &&
                item.state !== 12
          }
          // new Date(item.start * 1000).getDate() === new Date(date).getDate() &&
          // new Date(item.start * 1000).getMonth() === new Date(date).getMonth() &&
          // new Date(item.start * 1000).getFullYear() === new Date(date).getFullYear()
      )
      // const itemDate = new Date(item.start * 1000)
      // const day = itemDate.getDate()
      // const month = itemDate.getMonth()
      // const year = itemDate.getFullYear()
      // return day === new Date(date).getDate() &&
      //     month === new Date(date).getMonth() &&
      //     year === new Date(date).getFullYear()


      let positions = []
      orders.forEach(order => {
        if (order.positions) {
          order.positions.forEach(position => {
            if (position.status === 1 && position.master && position.type === 1 && position.service && position.service.time) {
              positions.push(position)
            }
          })
        }
      })

      let masters = []
      positions.forEach(position => {
        masters.push(position.master)
      })
      masters.sort((a, b) =>  a.id - b.id)

      let uniqueMasterIds = []
      masters.forEach(master => {
        if (uniqueMasterIds.indexOf(master.id) === -1) {
          uniqueMasterIds.push(master.id)
        }
      })

      let responseString = ''
      for (let i = 0; i < uniqueMasterIds.length; i++) {
        const master = [ ...masters ].find(master => master.id === uniqueMasterIds[i])
        const time = [ ...positions ].filter(position => position.master.id === uniqueMasterIds[i]).reduce((sum, position) => sum + (position.service.time * position.qty || 0), 0)
        responseString += master.first_name[0] + '.' + ' ' + master.last_name + ': ' + time?.toFixed(2) + ' ' + this.$t('h') + '.'
        if (uniqueMasterIds.length > 0 && i !== uniqueMasterIds.length - 1) {
          responseString += ', '
        }
      }
      return responseString
    },
	},
  computed: {
    allDataLoaded() {
      return !!(this.masters && this.responsibles && this.taxes && this.services && this.suppliers && this.areas && this.warehouseItems)
    },
    actionButtonsCount() {
      if (this.$store.state.user.role === this.constants.userRoles.superAdmin || this.$store.state.user.role === this.constants.userRoles.admin) {
        return 3
      } else {
        return 2
      }
    },
    itemToDeleteData() {
    //   // if (this.itemToDelete.role === 8) {
    //   //   return this.itemToDelete.company_name
    //   // } else {
    //   //   return (this.itemToDelete.first_name ? (this.itemToDelete.first_name) : '')  + (this.itemToDelete.last_name ? ' ' + this.itemToDelete.last_name : '')
    //   // }
      return 'AAAA!'
    },
  }
}
</script>

<style scoped lang="scss">
.no-data-block {
  padding: 12vh 0;
  //height: 100%;
  text-align: center;
}
</style>